import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton, Box, useTheme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SchoolIcon from '@mui/icons-material/School';
import { FiChevronDown } from 'react-icons/fi';
import { Link as ScrollLink } from 'react-scroll'; // For smooth scrolling
import './Navbar.css';

const Navbar = ({ sections }) => {
  const [anchorEl, setAnchorEl] = useState(null);   // For desktop "OTHER" menu
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);  // For mobile menu

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));  // Use MUI breakpoints for mobile

  // Handlers
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);  // Open desktop "OTHER" menu
  };

  const handleMobileMenuClick = (event) => {
    setMobileAnchorEl(event.currentTarget);  // Open mobile menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null);  // Close desktop "OTHER" menu
    setMobileAnchorEl(null);  // Close mobile menu
  };

  return (
    <Box>
      <AppBar position="fixed" color="primary" sx={{ width: '100vw', left: 0, top: 0 }}>
        <Toolbar>
          {/* Branding with a school icon next to your name */}
          <Typography 
            variant="h5" 
            sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
          >
            <SchoolIcon sx={{ marginRight: '8px' }} />
            Guillermo Tafoya
          </Typography>

          {/* Mobile Layout */}
          {isMobile && (
            <>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMobileMenuClick}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={mobileAnchorEl}
                open={Boolean(mobileAnchorEl)}
                onClose={handleMenuClose}
              >
                {sections.map((section) => (
                  <MenuItem
                    key={section.id}
                    onClick={handleMenuClose}
                  >
                    {section.label}
                  </MenuItem>
                ))}
                {/* OTHER */}
                <MenuItem onClick={() => { handleMenuClose(); window.location.href = '/projects'; }}>Projects</MenuItem>
                <MenuItem onClick={() => { handleMenuClose(); window.location.href = '/hackathons'; }}>Hackathons</MenuItem>
                <MenuItem onClick={() => { handleMenuClose(); window.location.href = '/volunteer-experience'; }}>Volunteer Experience</MenuItem>
              </Menu>
            </>
          )}

          {/* Desktop Layout */}
          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {sections.map((section) => (
                <Button
                  key={section.id}
                  color="inherit"
                  component={ScrollLink}
                  to={section.id}
                  smooth
                  duration={500}
                  spy
                  offset={-70}
                  className="navbar-item"
                  activeClass="active-section"
                >
                  {section.label}
                </Button>
              ))}

              {/* Desktop "OTHER" Dropdown */}
              <Button 
                color="inherit" 
                onClick={handleMenuClick} 
                endIcon={<FiChevronDown />} 
                sx={{ textTransform: 'none' }}
              >
                OTHER
              </Button>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={() => { handleMenuClose(); window.location.href = '/projects'; }}>Projects</MenuItem>
                <MenuItem onClick={() => { handleMenuClose(); window.location.href = '/hackathons'; }}>Hackathons</MenuItem>
                <MenuItem onClick={() => { handleMenuClose(); window.location.href = '/volunteer-experience'; }}>Volunteer Experience</MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
