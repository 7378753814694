import React from 'react';
import { Card, CardContent, ListItem, ListItemText, Typography, Link as MuiLink } from '@mui/material';
import ResourceLinks from './ResourceLinks';

const boldName = "Guillermo Tafoya Milo";

const renderAuthors = (authors) => {
  return authors.split(', ').map((author, index) => (
    <React.Fragment key={index}>
      {author === boldName ? <b>{author}</b> : author}
      {index < authors.split(', ').length - 1 && ', '}
    </React.Fragment>
  ));
};

const AcademiaItem = ({ authors, title, eventOrSource, date, link, note, slides, media, document, certificate, poster }) => (
    <Card sx={{ marginBottom: 2, backgroundColor: 'rgba(255, 255, 255, 0.7)'}}>
    <CardContent>
      <ListItem>
        <ListItemText
          primary={
            <Typography variant="body1" component="span">
              {/* Render authors with your name bolded */}
              {renderAuthors(authors)}. {title}.
              {/* Render event or source (flexible text) */}
              {eventOrSource && ` ${eventOrSource}`}
              {link && (
                <>
                  {" "}
                  <MuiLink href={link} target="_blank" rel="noopener noreferrer">
                    {title}
                  </MuiLink>
                </>
              )}
              {/* Render date, if applicable */}
              {date && `, ${date}.`}
              {/* Render optional note */}
              {note && <i> {note}</i>}
            </Typography>
          }
        />
      </ListItem>
      {/* Add ResourceLinks to display slides, media, document, certificate */}
      <ResourceLinks slides={slides} media={media} document={document} certificate={certificate} poster={poster} />
    </CardContent>
  </Card>
);

export default AcademiaItem;
