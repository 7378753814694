import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import Navbar from './components/Navbar.js';
import AboutMe from './pages/AboutMe.js';
import Projects from './pages/Projects.js';
import Main from './pages/Main.js';
// import Entrepreneurship from './pages/Entrepreneurship';
import Academia from './pages/Academia.js';
import Experience from './pages/Experience.js';
// import Milestones from './pages/Milestones';
// import Hackathons from './pages/Hackathons';
// import VolunteerExperience from './pages/VolunteerExperience';
import './App.css';

// Array of section data
const sections = [
  { id: 'about-me', component: AboutMe, label: 'About Me' },
  { id: 'academia', component: Academia, label: 'Academia' },
  { id: 'experience', component: Experience, label: 'Experience' },
  { id: 'projects', component: Projects, label: 'Projects' },
];

function App() {
  
  return (
    <div>
      <Navbar sections={sections} />
      <Box sx={{ paddingTop: { xs: '56px', md: '64px' } }}> 
        <Routes>
          <Route path="*" element={<Main sections={sections} />} />
          {/* <Route path="/test" element={<Test />} /> */}
        </Routes>
      </Box>
    </div>
  );
}

export default App;
