// Projects.js
import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import ProjectCard from '../components/ProjectCard';  // Reusable card component

const Projects = () => {
  const projects = [
    {
      title: 'Password Manager',
      description: 'A secure password management tool built using full-stack technologies.',
      link: '/projects/password-manager',
      image: 'path-to-password-manager-image.jpg'
    },
    {
      title: 'Chess AI',
      description: 'Developed an AI-powered chess engine using Python and reinforcement learning.',
      link: '/projects/chess-ai',
      image: 'path-to-chess-ai-image.jpg'
    },
    {
      title: 'Metflix',
      description: 'A movie streaming platform built using React and Node.js, mimicking Netflix.',
      link: '/projects/metflix',
      image: 'path-to-metflix-image.jpg'
    }
  ];

  return (
    <Box sx={{ padding: '2rem' }}>
      <Typography variant="h4" gutterBottom style={{marginBottom: '2rem' }}>
        Projects
      </Typography>
      <Grid container spacing={4}>
        {projects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ProjectCard {...project} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Projects;
