import React from 'react';
import { Box, Link as MuiLink } from '@mui/material';

const ResourceLinks = ({ slides, media, document, certificate, poster }) => {
  return (
    <Box sx={{ marginTop: 1, paddingLeft: 2, display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
      {/* Conditionally render the links */}
      {slides && (
        <MuiLink href={slides} target="_blank" rel="noopener noreferrer" sx={{ marginRight: 2 }}>
          [Slides]
        </MuiLink>
      )}
      {media && (
        <MuiLink href={media} target="_blank" rel="noopener noreferrer" sx={{ marginRight: 2 }}>
          [Media]
        </MuiLink>
      )}
      {document && (
        <MuiLink href={document} target="_blank" rel="noopener noreferrer" sx={{ marginRight: 2 }}>
          [Document]
        </MuiLink>
      )}
      {certificate && (
        <MuiLink href={certificate} target="_blank" rel="noopener noreferrer">
          [Certificate]
        </MuiLink>
      )}
      {poster && (
        <MuiLink href={poster} target="_blank" rel="noopener noreferrer" sx={{ marginRight: 2 }}>
          [Poster]
        </MuiLink>
      )}
    </Box>
  );
};

export default ResourceLinks;
